import React from "react"
import "./ListingItem.scss"
import { Link } from "gatsby"
import Box from "../../Box/Box"

const ListingItem = ({ data }) => {
  const description = data.node.frontmatter.desc.substr(0, 100)
  return (
    <Box height>
      <div className="ListingItem">
        <div className="ListingItem__title">
          <Link to={data.node.frontmatter.slug}>
            {data.node.frontmatter.title}
          </Link>
        </div>
        <div className="ListingItem__date">
          <p>{data.node.frontmatter.date}</p>
        </div>
        <div className="ListingItem__desc">
          <p>{description}...</p>
        </div>
        <div className="ListingItem__button">
          <Link to={data.node.frontmatter.slug}>Zobacz</Link>
        </div>
      </div>
    </Box>
  )
}

export default ListingItem
