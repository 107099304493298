import React from "react"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader/PageHeader"
import Container from "../components/Container/Container"
import Seo from "../components/Seo/Seo"
import ListingItem from "../components/Blog/ListingItem/ListingItem"
import GridWrapper from "../components/Blog/GridWrapper/GridWrappers"
import Pagination from "../components/Blog/Pagination/Pagination"
import Footer from "../components/Footer/Footer"
import Breadcrumbs from "../components/Breadcrumbs/Breadcrumbs"
import Box from "../components/Box/Box"

const Blog = ({
  pageContext,
  location,
  data: {
    allMarkdownRemark: { edges },
  },
}) => {
  const posts = edges.filter(edge => !!edge.node.frontmatter.date)
  return (
    <>
      <Seo
        title="Blog | CSSGENERATOR.PL"
        description="Na blogu znajdziesz wpisy poradnikowe z zakresu HTML i CSS, artykuły o programowaniu i inne materiały z branży IT. Zapraszamy do lektury."
        location={location}
      />
      <PageHeader
        h1="Blog"
        desc="Na blogu znajdziesz wpisy poradnikowe z zakresu HTML i CSS, artykuły o programowaniu i inne materiały z branży IT."
        notGenerator
      />
      <Container>
        <Box>
          <Breadcrumbs items={[{ name: "Blog" }]} />
        </Box>

        <GridWrapper>
          {posts.map(item => (
            <ListingItem key={item.node.id} data={item} />
          ))}
        </GridWrapper>
        <Pagination numPages={pageContext.numPages} />
      </Container>
      <Footer />
    </>
  )
}

export default Blog

export const pageQuery = graphql`
  query ($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      skip: $skip
      limit: $limit
    ) {
      edges {
        node {
          id
          frontmatter {
            date(formatString: "D MMMM, YYYY", locale: "pl")
            slug
            title
            desc
          }
        }
      }
    }
  }
`
