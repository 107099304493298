import React from "react"
import "./Pagination.scss"
import { Link } from "gatsby"

const Pagination = ({ numPages }) => {
  return (
    <div className="pagination__wrapper">
      <ul>
        {[...Array(numPages)].map((item, index) =>
          index === 0 ? (
            <li key={index + 1}>
              <Link activeClassName="active" to={`/blog/`}>
                {index + 1}
              </Link>
            </li>
          ) : (
            <li key={index + 1}>
              <Link activeClassName="active" to={`/blog/${index + 1}/`}>
                {index + 1}
              </Link>
            </li>
          )
        )}
      </ul>
    </div>
  )
}

export default Pagination
